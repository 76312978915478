import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { UsersService } from "src/app/users/users.service";
import { SportService } from "src/app/sports/sport.service";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { DashboardService } from "../dashboard.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import thLocale from "@fullcalendar/core/locales/th";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    public user: any = null;
    public dashboard: any = null;
    public users: any = [];
    public fixtures: any = null;
    public calendarEvents = [];
    public isShowCalendar = false;
    public calendarPlugins: any;

    constructor(
        private localStorage: LocalStorage,
        private router: Router,
        private userService: UsersService,
        public sportService: SportService,
        private customJsService: CustomJsService,
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService
    ) {
        this.spinner.show();
        localStorage.getItem("user").subscribe((user) => {
            if (user === null) {
                this.router.navigate(["/login"]);
            }

            this.getUsers();
        });

        localStorage.getItem("_user").subscribe((data) => {
            this.user = data;
        });

        this.get();
        this.calendarPlugins = [dayGridPlugin, timeGridPlugin, listPlugin];
        this.getFixtures();
    }

    ngOnInit() {}

    getFixtures() {
        this.dashboardService.getFixtures().subscribe((res) => {
            this.fixtures = res.result;
            this.setEvents(res.result);
        });
    }

    getUsers() {
        this.userService.getUsers().subscribe((data) => {
            for (let i = 0; i < data.length; i++) {
                this.users.push(data[i]);

                if (i === 9) {
                    return;
                }
            }
        });
    }

    get() {
        this.dashboardService.getDashboard().subscribe((res) => {
            this.dashboard = res;
            setTimeout(() => {
                this.spinner.hide();
            }, 500);
        });
    }

    setEvents(data: any) {
        let eventTemplate = {
            title: "",
            start: "",
            color: "#f35e90", // Static
        };

        if (data === undefined || data.length === 0) {
            // setTimeout(() => {
            //     this.spinner.hide();
            // }, 500);

            return;
        }

        for (let i = 0; i < data.length; i++) {
            this.calendarEvents.push({
                title: `${data[i].category.sport_name}, ${data[i].category.name}, ${data[i].category.age_group_description} : ${data[i].name}, ${data[i].stadium_name}`,
                start: `${data[i].date}T${data[i].time}`,
                color: "#f35e90",
            });

            if (i === data.length - 1) {
                setTimeout(() => {
                    this.isShowCalendar = true;
                    this.spinner.hide();
                }, 500);
            }
        }
    }
}
