import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-users",
    templateUrl: "./user-edit.component.html",
    styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
    public user: any = null;

    constructor(
        private userService: UsersService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService
    ) {
        this.spinner.show();
        const param: any = this.route.snapshot.paramMap.get("id");
        param;
        this.userService.getUserById(param).subscribe((data) => {
            this.user = data;
            this.spinner.hide();
        });
    }

    ngOnInit() {}

    toggleStatus() {
        let param = this.user;
        param.override_system_setting_allow_register =
            !this.user.override_system_setting_allow_register;

        this.userService.editUser(this.user.id, param).subscribe((data) => {
            console.log(data);
        });
    }
}
