// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    whitelist: ["https://api.siamplus.co.th/"],
    api: "https://api.siamplus.co.th/v1/",
    base: "https://api.siamplus.co.th/",
    public_token:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTZiOWYwNjRhNWQ3ZjZhMjgyMmI1MTllMDJjNDFkOGY0MWRmMzFhNTlmYTgwYWY5YTA5NmU3OTUxZjE2OWY0NDAzYzNhNmM4YTE5OTFkYmYiLCJpYXQiOjE3MzYzOTEyMDkuNDgyNjc1LCJuYmYiOjE3MzYzOTEyMDkuNDgyNjc3LCJleHAiOjE3Njc5MjcyMDkuNDc1MDczLCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.k1M8CLo9CPdU-iRxNolf_E8H_H9SVn4E9g_0ZZll8hQMPbR_sLiPZv9empUaRB4pEPT6HYr-sYgN0i9V51qLf83vHrMGGJYOpdibLCuhmIPqwXCtxf48Hm4-AAlYn8cIQ5eJ0e5VHj8nCKHmWgy2XlcMlDxmpG2xwT7rUqC28rhPUsXYUMUmMjpN2lceMCjQRTXQoqsjUlqINglR6_11cdOXgWwcyqgWmMdqq0XE3DNBINrer3CrbH2seUMmSuuLoNb0hTQUnl8Y239crFbtSCS8nZFMClMVCfWnOcJGQIzfp_Bunds9Sk8R4a6j4dW8bc1FRL-t9EpesLOAiwHyozkShPBZjczScGPUmB4m4aK48be87ZFIn_FvwlFaewKy8Y4LbGMK9rOOKmI5rWW2IQU9pd6jY_xM3-7DIZJaDqhS1B3dKmD9f4tezEmiSccWnEhnLnvcOiFuz4KDjlyr1X0zii2BfPIDLHxwb2KgDdyn3XcjSFpJR-AFDpH0RtyC7eKSVXMPHlQlQRVww6ElMq-2NGMldy9Fu7JFkr4eBR3H9a5Gxqkxtyre917IX3LtJNZGLK6eGZ9YpGQIYEOiT13PILVH7FYs_1zTP9TapcFKWLwrBmhdfrCAkQUnl4oxZxP0NTYYdwH5jOdO3bPsUgtDoqYzhpQhPnzCnlFA6Qs",
    client_secret: "AiDEuKRotDn9uDbdOewzhpjEHHtERMUCSM5eNgoE",

    // whitelist: ["https://api.nakhonsawan-pasangames.com/"],
    // api: "https://api.nakhonsawan-pasangames.com/v1/",
    // base: "https://api.nakhonsawan-pasangames.com/",
    // public_token:
    //     "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzU1ZjhhMDUwZGE4NTA5NDYwZmQ3YWZlODc3NDI2MTRmYzQyY2U1YzM1NjBlNzdkYzFjZmM0YmMxYjFmZWI5MjlhNGVkZTQ1MTA1MzM1ZGYiLCJpYXQiOjE3Mjk0MDA3MTkuODM1MTQ4LCJuYmYiOjE3Mjk0MDA3MTkuODM1MTUxLCJleHAiOjE3NjA5MzY3MTkuODI3NTU0LCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.d95BSPMP9CwQrNfs5heT92vF2imGXdlkMk3sU_7c1JnNpmoHnkB9tJ7AiFXrNpIPMAUjzPPuGJyOWgbeC7nHrcczSKl-E_YF10YPZXBsdjhc-ClU1y0kHBRBh-nNYd0JMAh2eZjrxHWGc_PHIFbaXgIIaZh-vAsWsE9HYWEsON-ZLbyWGilYLtyRzHmxd979AqsfAqrPYwmDawW6tKrqXAlbDHcRoQuF7UgOpiWGvu2YwYl966vmn_onFIjBGRuZEOusfBdyWCoZpnchX8W3dT1TR08YQtCkDwP1Y1o6sstHmOYsdZ5IGfJ2Gq-i01pJ2eK4Ei8fNpvcHslGiQ1fsHj04EBKWXqfD3MLqbQgC_EbH6F1OESMf8Ly7q4i0nEhxo59cNdr0pVKUc7jAi-r0kjMmv-hd0GrYCPdoB3a6M3w5AIEDJhOboGRMlGK6FHJlUlklIxLHZyc3yIuHDxY10ffiTQpL3SJLvabZwM7QXS5roP_OmkiFbhf1ESFCEzQBKilh-HrqPcft33qiMnddTZUxVgxg6y04I6yg_eOXpMUx-4fQqT7KIZz9r7umszMDB6n2PKdvXLNZtlf-eyg_gVfnoqjMbUrnfZ2XUZT1kQTEgroGnQLfs2RGtRU3SGB_xNn5WkPGdqknnCzEN49rDIwKi3qHrQGKGJVa-YS4qM",
    // client_secret: "AiDEuKRotDn9uDbdOewzhpjEHHtERMUCSM5eNgoE",

    // whitelist: ["https://api.muangkondeegames39.com/"],
    // api: "https://api.muangkondeegames39.com/v1/",
    // base: "https://api.muangkondeegames39.com/",
    // public_token:
    //     "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYWEwNjE0MjBiYzYxZTBhZGE0NmVlOWVkNmU3MzdkNzI1NTliMTFkMTgwYTk5MzVhMzIwODE2ZWQzOWY1ZmYyOWI2ODQyNWY2Yzc4ZWQ5MGQiLCJpYXQiOjE3MzUxMTQ2ODcuNTgzNjM4LCJuYmYiOjE3MzUxMTQ2ODcuNTgzNjQyLCJleHAiOjE3NjY2NTA2ODcuNTc1MjczLCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.ZW4Rs73NdWJ3Vtqx-0PxKeXPftHqmd7wI4gZYe8s3dU0fLD0oviLGEups_qBVubH97a_Ut0pgPhlphh024Z8X4t2A2Bcfa6Ee6u5P2V1WehXweik9a6YgoZ4idpDJfBzJK3seDu2pOVzHCdv0SYjVeHywzVR1XeZ8yvf5sYlVri9apPlScQcpzcIOWBIAYD-evTwTQ2cHMyb9hKi3zcdw0nd86geRVpPLAwLPirMsYjb_y36NB3OWqMSdqTnJgbOTR2lpo9Sv2wnxTF-BAHePDzVDaqaiq0hZcvNcvLRk5xgwzthpZFjuw9NDxzfR3TOCha26ciSB7pNqJRzvaR1TSQzmQI1CSsvfqEIJzpK8j3E66BAp6E1P71yg6wNgUgHgpVtKR19CuJLcy3q5zT2F1rDvh9h0WcWAvnS-V2GxWIGfe7A4i7q9gjZ6uKPzgeDzP_e8iyHRcbTy8o4FUaeB_B8sSrzClkca1DGdj8XsB3JVcpBEJKxfjEjFkoDo6nfOp4Ue9UW4R8yGP6EIIZexCYNGxHLd3CO905NEAGS9KY46k2wrHoSTSMziF5cp2etmxDRnwATdutIihOqDlYu5cUM__K3nJpR3Q2J-LxavjVyRhyCrfTvc1IpzdAJcEuJ2i0FaaWEmfLeD8BE-3dbbaMVwnK-TpAUXWA7fNyQsXY",
    // client_secret: "tq4Lpq6fUQuU8L0NcdmcJpE2suwLYIEDG7gSAyHY",

    grant_type: "password",
    client_id: 1,
    is_disable_id_print: false,
    is_disable_certificate: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
