import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LoginStageService } from "src/app/shared/login-stage.service";

@Component({
    selector: "app-users-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
    constructor(
        private userService: UsersService,
        private spinner: NgxSpinnerService,
        private loginStageService: LoginStageService
    ) {
        this.spinner.show();
        this.loginStageService.setStage(false);
        this.userService.logout();
    }

    ngOnInit() {}
}
