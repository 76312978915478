import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-user-sport-category-print",
    templateUrl: "./user-sport-category-print.component.html",
    styleUrls: ["./user-sport-category-print.component.scss"],
})
export class UserSportCategoryPrintComponent implements OnInit {
    public token: any = null;
    public sport: any = null;
    public categories: any = null;
    public user: any = null;
    public isChrome = false;
    public timeout: any = null;
    public atheticCategories: any = null;
    public athletes: any = null;

    constructor(
        private userService: UsersService,
        private localStorage: LocalStorage,
        private route: ActivatedRoute,
        private deviceService: DeviceDetectorService,
        private spinner: NgxSpinnerService
    ) {
        this.spinner.show();
        const device = this.deviceService.getDeviceInfo();
        const lowerName = device.browser.toLowerCase();
        this.isChrome = lowerName === "chrome";

        this.pageLoad();
    }

    pageLoad() {
        this.localStorage.getItem("user").subscribe((data) => {
            this.token = data;

            this.route.paramMap.subscribe((params: ParamMap) => {
                this.getSportCategory(1);
            });

            this.userService.getUser(data).subscribe((res) => {
                this.user = res;
            });
        });
    }

    ngOnInit() {}

    getSportCategory(id: any) {
        this.userService
            .getUserAthletSports(id, this.token)
            .subscribe((res) => {
                this.athletes = res;
                this.getUserSportCategory();
            });
    }

    includesAny(array: number[], values: number[]): boolean {
        return values.some((value) => array.includes(value));
    }

    getUserSportCategory() {
        this.userService
            .getUserSportCategory(this.token, 1)
            .subscribe((res) => {
                this.sport = res;
                this.prepreAthlectic();
                this.spinner.hide();
            });
    }

    prepreAthlectic() {
        const categories = Object.keys(this.sport.groupedCategories).map(
            (category, index) => ({
                index,
                name: category,
            })
        );

        this.atheticCategories = categories;
        console.log(this.athletes);
        // this.athletes = athletes;
        // console.log(this.sport);
        // console.log(categories);
        // console.log(athletes);

        window.addEventListener("afterprint", () => {
            // window.close();
        });

        setTimeout(() => {
            window.print();
        }, 1000);
    }

    printAthleticsSheet() {
        // this.spinner.show();
        console.log(this.sport);
    }
}
