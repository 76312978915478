import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, Validators } from "@angular/forms";
import { UsersService } from "../users.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

declare var jquery: any;
declare var $: any;

@Component({
    selector: "app-add-users",
    templateUrl: "./add-users.component.html",
    styleUrls: ["./add-users.component.scss"],
})
export class AddUsersComponent implements OnInit {
    public registerData: any;

    constructor(
        private spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private toastr: ToastrService,
        private router: Router
    ) {
        this.initForm();
        this.spinner.show();
    }

    ngOnInit() {
        this.spinner.hide();
    }

    thaiAddress() {
        $.Thailand({
            $district: $("#district"),
            $amphoe: $("#amphoe"),
            $province: $("#province"),
            $zipcode: $("#zipcode"),
            onDataFill: (data) => {
                this.registerData.controls.sub_district.setValue(data.district);
                this.registerData.controls.district.setValue(data.amphoe);
                this.registerData.controls.province.setValue(data.province);
                this.registerData.controls.zipcode.setValue(data.zipcode);
            },
        });
    }

    initForm() {
        // this.registerData.controls.user_name.setValue(this.currentUser.name);
        this.registerData = this.formBuilder.group({
            username: ["", Validators.required],
            name: ["", Validators.required],
            password: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                ]),
            ],
            is_admin: [0],
            image: ["/images/default-logo.png"],
            override_system_setting_allow_register: [0],
            sub_district: ["", Validators.required],
            district: ["", Validators.required],
            province: ["", Validators.required],
            zipcode: ["", Validators.required],
            region_id: ["", Validators.required],
        });

        setTimeout(() => {
            this.thaiAddress();
            this.spinner.hide();
        }, 500);
    }

    registerSubmit(value) {
        const registerData = {
            user_name: value.username,
            name: value.name,
            password: value.password,
            is_admin: 0,
            image: "/images/default-logo.png",
            override_system_setting_allow_register: 0,
            sub_district: value.sub_district,
            district: value.district,
            province: value.province,
            zipcode: value.zipcode,
            region_id: value.region_id,
        };

        this.usersService.add(registerData).subscribe((res) => {
            this.toastr.success("Success", "เพิ่ม อปท สำเร็จ");
            this.router.navigate(["/user/all"]);
        });
    }
}
