import { MomentModule } from "ngx-moment";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { QRCodeModule } from "angularx-qrcode";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { StorageModule } from "@ngx-pwa/local-storage";
import { UsersModule } from "./users/users.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { FullCalendarModule } from "@fullcalendar/angular";

// Services
import { CustomJsService } from "./shared/custom-js.service";
import { LoginStageService } from "./shared/login-stage.service";
import { tokenGetter, TokenService } from "./shared/token.service";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header/header.component";
import { SidemenuComponent } from "./sidemenu/sidemenu/sidemenu.component";
import { FooterComponent } from "./footer/footer/footer.component";
import { DeviceDetectorModule } from "ngx-device-detector";
import { NgxPermissionsModule } from "ngx-permissions";
import { NotfoundComponent } from "./errorpage/notfound/notfound.component";
import { InternalErrorComponent } from "./errorpage/internal-error/internal-error.component";
import { UnauthorizedComponent } from "./errorpage/unauthorized/unauthorized.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthService } from "./auth/auth.service";
import { AuthGuardService } from "./auth/auth-guard.service";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidemenuComponent,
        FooterComponent,
        NotfoundComponent,
        InternalErrorComponent,
        UnauthorizedComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        FullCalendarModule,
        ToastrModule.forRoot(),
        StorageModule.forRoot({
            IDBNoWrap: true,
        }),
        UsersModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: [
                    "localhost:4200",
                    "siamplus.co.th",
                    "app.nakhonsawan-pasangames.com",
                ],
            },
        }),
        DashboardModule,
        NgxQRCodeModule,
        NgxPermissionsModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        QRCodeModule,
        MomentModule,
        CommonModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        AuthService,
        AuthGuardService,
        CustomJsService,
        LoginStageService,
        TokenService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
