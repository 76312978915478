import { Component } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { CustomJsService } from "./shared/custom-js.service";
import { LoginStageService } from "./shared/login-stage.service";
import { UsersService } from "src/app/users/users.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    public title = "Pasan Games";
    public userToken: any = null;
    public loggedIn = false;

    constructor(
        private localStorage: LocalStorage,
        private router: Router,
        public customJsService: CustomJsService,
        private loginStageService: LoginStageService,
        private userService: UsersService
    ) {
        localStorage.getItem("user").subscribe((user) => {
            this.checkToken(user);
        });
    }

    async checkToken(user) {
        if (user !== null) {
            try {
                const response = await this.userService.getUserToken(user);
                this.userService.setupPermissions(response);
                this.loggedIn = true;
                this.loginStageService.setStage(true);

                localStorage.setItem("user", user);
            } catch (error) {
                console.log("error", error.status);
                this.router.navigate(["/user/logout"]);
            }
        } else {
            localStorage.removeItem("user");
        }

        this.loginStageService.isLoggedIn$.subscribe((data) => {
            return (this.loggedIn = data);
        });
    }
}
