import { Injectable } from "@angular/core";

declare var jquery: any;
declare var $: any;

@Injectable({
    providedIn: "root",
})
export class CustomJsService {
    constructor() {}

    init() {
        $("#parentVerticalTab").easyResponsiveTabs({
            type: "vertical",
            width: "auto",
            fit: true,
            closed: "accordion",
            tabidentify: "hor_1",
            activate: (event) => {
                const $tab = $(this);
                const $info = $("#nested-tabInfo2");
                const $name = $("span", $info);
                $name.text($tab.text());
                $info.show();
            },
        });

        $(".vscroll").mCustomScrollbar();
        $(".app-sidebar").mCustomScrollbar({
            theme: "minimal",
            autoHideScrollbar: true,
            scrollbarPosition: "outside",
        });
    }

    owlCarousel() {
        $(".owl-carousel2").owlCarousel({
            loop: true,
            margin: 25,
            autoplay: true,
            nav: false,
            dots: false,
            slideTransition: "linear",
            autoplayTimeout: 3700,
            smartSpeed: 3700,
            animateIn: "linear",
            animateOut: "linear",
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true,
                },
                600: {
                    items: 2,
                    nav: true,
                },
                1300: {
                    items: 4,
                    nav: true,
                },
                1600: {
                    items: 5,
                    nav: true,
                },
            },
        });

        $(".profiles").owlCarousel({
            loop: true,
            margin: 25,
            autoplay: true,
            nav: false,
            dots: false,
            slideTransition: "linear",
            autoplayTimeout: 1720,
            smartSpeed: 1500,
            animateIn: "linear",
            animateOut: "linear",
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true,
                },
                600: {
                    items: 2,
                    nav: true,
                },
                1300: {
                    items: 4,
                    nav: true,
                },
            },
        });
    }

    dropify() {
        $(".dropify-register").dropify({
            messages: {
                default: "ลากไฟล์ หรือกดที่นี่เพื่อเลือกรูปภาพ",
                replace: "ลากไฟล์ หรือกดที่นี่เพื่อเปลี่ยนรูปภาพ",
                remove: "ลบ",
                error: "Ooops, something wrong appended.",
            },
            error: {
                fileSize: "ขนาดไฟล์เกิน 2 mb",
            },
        });
    }

    accordian() {
        $(".demo-accordion").accordionjs();
    }

    dataTableDefault(element: any, searchValue = "") {
        $(element).DataTable({ pageLength: 25 });

        if (searchValue && searchValue !== "") {
            $(element).DataTable().search(searchValue).draw();
        }
    }

    destroyDataTable(element: any) {
        if ($.fn.DataTable.isDataTable(element)) {
            $(element).DataTable().destroy();
        }
    }

    dataTable($element: any) {
        $element.DataTable({
            pageLength: 100,
            order: [[2, "asc"]],
        });
    }

    dataTableFixtures(element: any) {
        $(element).DataTable({
            pageLength: 50,
            order: [[0, "asc"]],
        });
    }

    dataTableOption(element: any, options: any) {
        $(element).DataTable(options);
    }

    fixtureCalendar(events: any) {
        //     const calendar = $('#fixtures-calendar').fullCalendar({
        //         header: {
        //             left: 'prev,next today',
        //             center: 'title',
        //             right: 'month,agendaDay,listMonth'
        //         },
        //         locale: 'th',
        //         navLinks: true, // can click day/week names to navigate views
        //         businessHours: true, // display business hours
        //         events: events
        //     });
        //     calendar.setOption('locale', 'th');
    }
}
