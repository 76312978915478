import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { SportService } from "src/app/sports/sport.service";
import { SettingsService } from "src/app/settings/settings.service";
import { set } from "lodash";

@Component({
    selector: "app-all-users",
    templateUrl: "./all-users.component.html",
    styleUrls: ["./all-users.component.scss"],
})
export class AllUsersComponent implements OnInit {
    public users: any = null;

    constructor(
        private localStorage: LocalStorage,
        private userService: UsersService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private customJsService: CustomJsService,
        private sportService: SportService,
        private settingsService: SettingsService
    ) {
        this.spinner.show();
        this.getUsersData();
    }

    getUsersData(searchValue = "") {
        this.userService.getUsers().subscribe((res) => {
            this.users = res;
            setTimeout(() => {
                this.initDataTable(searchValue);
            }, 500);
        });
    }

    ngOnInit() {}

    initDataTable(searchValue: string) {
        const usersTable: any = document.getElementById("user-table");
        this.customJsService.dataTableDefault(usersTable, searchValue);

        setTimeout(() => {
            this.spinner.hide();
        }, 500);
    }

    changeStatus(item: any) {
        if (confirm("ต้องการเปลี่ยนสถานะการลงทะเบียนใช่ไหม")) {
            this.spinner.show();
            this.settingsService
                .changeStatuse(
                    item.id,
                    !item.override_system_setting_allow_register
                )
                .subscribe((res) => {
                    const usersTable: any =
                        document.getElementById("user-table");
                    this.customJsService.destroyDataTable(usersTable);
                    this.getUsersData(item.name);
                });
        } else {
            this.spinner.hide();
            console.log("ยกเลิก");
        }
    }

    exportUserAthletes(soprt_id, user_id: number) {
        this.sportService.exportSportAthletes(soprt_id).subscribe((data) => {
            console.log(data);
        });
    }
}
